
import './App.css';

function App() {
  return (
    <div className="bg-red-200 h-screen flex justify-center items-center">
      Hello World
    </div>
  );
}

export default App;
